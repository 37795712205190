import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/nl/analytics-consulting",
  "GTM Tutorial": "/nl/google-tag-manager-setup"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-tag-manager-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-tag-manager-einrichten"
  },
  {
    hreflang: "se",
    href: "/se/google-tag-manager-installation"
  },
  {
    hreflang: "da",
    href: "/da/google-tag-manager-opsætning"
  },
  {
    hreflang: "no",
    href: "/no/google-tag-manager-oppsett"
  },
  {
    hreflang: "nl",
    href: "/nl/google-tag-manager-setup"
  },
  {
    hreflang: "x-default",
    href: "/en/google-tag-manager-setup"
  }
];

const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Heb ik Google Tag Manager nodig?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ja, omdat je website waarschijnlijk Google Analytics en andere third-party script tags wilt gebruiken. Het instellen van al die dingen is veel eenvoudiger en sneller met Google Tag Manager. Bovendien <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>laadt je site ook wat sneller</a>."
        }
      },
      {
      "@type": "Question",
      "name": "Wanneer moet ik Google Tag Manager gebruiken?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Zelfs als je alleen Google Analytics wilt gebruiken, moet je Google Tag Manager gebruiken. Het instellen van <a href='https://bluerivermountains.com/nl/event-tracking'>event tracking</a> en andere configuraties gaat veel sneller en eenvoudiger met GTM. Bovendien zijn er veel tutorials en handleidingen online die uitleggen hoe je bijvoorbeeld <a href='http://bluerivermountains.com/nl/google-analytics-installatie'>Google Analytics kunt instellen</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Hoe gebruik ik Google Tag Manager met Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Voeg de Google Analytics-tag niet toe aan de broncode van je site. Voeg alleen de Google Tag Manager-tag toe en implementeer en <a href='https://bluerivermountains.com/nl/google-analytics-installatie'>stel Google Analytics in</a> via Google Tag Manager. Eventuele aangepaste configuraties zoals <a href='https://bluerivermountains.com/nl/event-tracking'>event tracking</a> of het toevoegen van andere script tags, doe je in GTM."
        }
     },
     {
       "@type": "Question",
       "name": "Wat is het verschil tussen Google Analytics en Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics is de bibliotheek die gegevens verzamelt over bezoeken aan je website. Google Tag Manager daarentegen is een bibliotheek die op je site draait om andere bibliotheken of tools zoals Google Analytics te implementeren. Omdat veel van die tools extra JavaScript-snippets hebben om gegevens naar hen te verzenden, stel je ze allemaal in via GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Waar plaats ik de Google Tag Manager-code?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Het eerste deel van de code gaat zo hoog mogelijk in de <head>-sectie. Ik raad aan om het binnen de <head> te implementeren, maar na eventuele <style> of <script>-tags die essentieel zijn om de pagina weer te geven - omdat we deze niet willen vertragen. Het tweede deel van de GTM-code snippet is om een basisfunctionaliteit op sites met uitgeschakelde JavaScript mogelijk te maken. Dit gaat zo hoog mogelijk in het <body>-element. De logica achter de positionering van beide tags is om de vroege laadtijd van GTM te garanderen. Het stelt je in staat om delen van het pagina-laadproces zo vroeg mogelijk te beïnvloeden en te controleren."
      }
   },
   {
     "@type": "Question",
     "name": "Bevat Google Tag Manager Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Nee, maar Google Tag Manager stelt je in staat om Google Analytics in enkele seconden te implementeren met slechts een paar klikken. Het enige wat je nodig hebt is je Google Analytics-tracking ID. Over het algemeen hoef je echter Google Analytics niet te gebruiken met Google Tag Manager. Ze zijn onafhankelijk van elkaar."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Installatie Google Tag Manager Tutorial: Setup Gids ${currentYear}`}
        description="Leer hoe je GTM kunt gebruiken om Google Analytics, event tracking, remarketing tags en een data layer in te stellen, plus best practices voor het bijhouden van downloads en externe links."
        lang="nl"
        canonical="/nl/google-tag-manager-setup"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="gebruikersinterface van Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Tutorial</H>

        <p>Als een <Link to="/nl/google-tag-manager-consultant">Google Tag Manager consultant</Link>, heb ik GTM ingesteld op <b>meer dan 100 klantwebsites</b>. Deze Google Tag Manager tutorial is waar ik je stap voor stap het proces leer dat ik door de jaren heen heb verfijnd, met voorbeelden en video's om van te leren.</p>
        <p>Verderop kun je een <Link to="/nl/google-tag-manager-setup#download-gtm-config-container-file">GTM setup configuratiebestand downloaden</Link> met alle volgende best practices om in je container te importeren.</p>
        <p>Als je niet kunt wachten, ga dan direct naar de <Link to="/nl/google-tag-manager-setup#install-google-tag-manager-on-your-website">installatie</Link> tutorial of leer <Link to="/nl/google-tag-manager-setup#how-to-set-up-google-tag-manager">hoe je Google Tag Manager installeert</Link>. Maar als je een <b>beginner</b> bent, is het belangrijk om eerst te begrijpen <em>hoe</em> je een <Link to="/nl/tagbeheer">tag management systeem</Link> samen met andere tools gebruikt.</p>
        <p>Dus lees eerst hieronder verder.</p>
        <H as="h2">Hoe gebruik je Google Tag Manager?</H>
        <p>Ik ga ervan uit dat je al weet <Link to="/nl/wat-is-google-tag-manager">wat Google Tag Manager is</Link>. Dus laten we praten over hoe GTM werkt en hoe je het gebruikt.</p>
        <p>Idealiter wil je slechts <b>één</b> third-party tag in de broncode van je website of web app hebben.</p>
        <QuoteBox
          quote={`De enige third-party tag op je website zou de Google Tag Manager code snippet moeten zijn.`}
        />
        <p>Alle andere tags worden vervolgens geïmplementeerd via de tag manager zelf. Andere marketing- en tracking tags zijn bijvoorbeeld Google Analytics (GA), Facebook, Twitter, LinkedIn, AdWords, DoubleClick en god weet wat nog meer.</p>
        <p>De belangrijkste reden zijn de <Link to="/nl/google-tag-manager-voordelen">voordelen van Google Tag Manager</Link>:</p>
        <ul>
          <li><b>eenvoudigere & snellere</b> implementatie van marketing tags</li>
          <li>schaalbaarheid op elke pagina en over meerdere domeinen</li>
          <li><b>ingebouwde triggers</b> voor formulierinzendingen, scroll tracking & video tracking</li>
          <li>gebruikers beheren met meerdere GTM-accounts</li>
          <li>een beetje <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">snellere laadtijd van de site</a></li>
        </ul><br />
        <p>Vanwege deze voordelen gebruikt al <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% van alle websites op het internet een tag manager</a>. En daarvan heeft Google Tag Manager een marktaandeel van <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Dus, tenzij je een solide reden hebt om geen tag aan GTM toe te voegen, als algemene vuistregel, <b>voeg alle tags toe aan de GTM-container</b>.</p>

        <QuoteBox
          quote={`Gebruik GTM als een verbindingslaag tussen je website en third-party tags.`}
        />
        <p>Gebruik GTM als een <b>tussenlaag</b> tussen je website en third-party services. Zonder dit zijn je site en third-party tags niet direct verbonden. Die services zijn meestal JavaScript-bibliotheken voor marketing- of trackingtools die met een tag worden geïmplementeerd. Maar andere tags gelden ook.</p>
        <p>De enige uitzondering op de regel geldt wanneer je conversieoptimalisatie uitvoert met split-testing tools.</p>
        <p>Omdat tijdens conversieoptimalisatie A/B-tests verschillende varianten van een pagina gaan laden. Dus de bezoeker kan zien hoe de inhoud een fractie van een seconde opnieuw wordt gerenderd.</p>
        <p>Om CSS-flikkering te voorkomen en ervoor te zorgen dat varianten snel laden, kan een split-testing tag ook direct in de broncode worden geplaatst.</p>
        <p>Nu we dit uit de weg hebben geruimd, laten we naar de implementatie kijken.</p>
        <H as="h2">Installeer Google Tag Manager op je website</H>
        <p>Laten we de Google Tag Manager tutorial beginnen door je te laten zien waar je de Google Tag Manager code snippet kunt krijgen en vervolgens waar je deze op de website installeert. Je kunt inloggen met je gebruikelijke Google-account.</p>
        <ol>
          <li><H as="h3">Maak een Google Tag Manager-account aan</H>
            Om GTM te installeren, moet je eerst naar de <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">officiële website</a> gaan en een nieuw Google Tag Manager-account aanmaken.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Google Tag Manager account aanmaken"
              caption="Maak eerst een Google Tag Manager-account aan, kies een containenaam, zoals je website naam en krijg vervolgens de code snippet."
              className="article-img"
            />
          </li>
          <li><H as="h3">Maak een web-eigendom aan</H>
            Selecteer de <b>Web</b> eigendom om een code te krijgen voor een website of web app.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM containenaam en web-eigendom selectie"
              className="article-img"
              caption="Er zijn meerdere soorten containers beschikbaar in een GTM-account. Voor websites kies je web. Merk op dat er ook andere typen tag manager containers zijn voor AMP-pagina's, iOS en Android."
            />
          </li>
          <li><H as="h3">Implementeer de Google Tag Manager code</H><ul>
            Vervolgens wordt je de Google Tag Manager code getoond om op je website te implementeren.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager code snippet"
              caption="Dit is de Google Tag Manager container tag. Het heeft twee delen. De instructies hoe je de script tags moet implementeren, staan boven elk deel geschreven."
              className="article-img"
            />
            <li>Neem het <b>eerste deel</b> van de <Link to="/nl/wiki-analytics/container-tag">container tag</Link> en plaats deze zo hoog mogelijk in de <b>head</b> tag op elke pagina van je website. Dit zorgt ervoor dat je tags vroeg tijdens het laden van de pagina kunt afvuren.</li>
            <li>Het <b>tweede deel</b> is een iframe om uit te voeren in browsers die JavaScript hebben uitgeschakeld. Implementeer de tag zo hoog mogelijk in de <b>body</b> tag op elke pagina van je website.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="de dataLayer is gepositioneerd vóór de TMS-tag in de broncode"
                caption={`De eerste tag in de <head> is een data layer. Maak je geen zorgen als je nog niet weet wat dat is (eerste pijl). Vervolgens komt het eerste deel van de GTM-tag (tweede pijl). Ten slotte wordt het andere deel van de GTM-tag hoog in het <body> element geïmplementeerd. Of JavaScript-code kan ertussen worden geïmplementeerd, maar een data layer wordt altijd vóór de GTM-tag geïmplementeerd en de <noscript> GTM-tag is altijd de laatste.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>Dit is de gebruikelijke methode om GTM te implementeren.</p>
        <p>Gebruik je een populair content management systeem? Zo ja, dan kun je ook een <b>plugin</b> gebruiken die de installatie van Google Tag Manager verzorgt.</p>
        <p>Dat gezegd hebbende:</p>
        <InfoBox
          type="info"
          headline="Als je CMS je ook een plugin biedt om andere tags te installeren"
          h="false"
          dots="true"
          tweetcopy="Als je CMS je ook een plugin biedt om andere tags te installeren, gebruik dan niet nog een plugin om Google Analytics, Facebook of Google Ads te installeren. Gebruik in plaats daarvan GTM om die tags te installeren."
        >
          <p>Gebruik niet nog een plugin om Google Analytics, Facebook of Google Ads te installeren.</p>
          <p>Gebruik in plaats daarvan <b>GTM om die tags te installeren</b>.</p><br />
          <ol>
            <li>Het zal resulteren in een snellere laadtijd van de pagina</li>
            <li>Het geeft je meer opties om de tag te configureren</li>
          </ol>
        </InfoBox>
        <p>De GTM gebruikersinterface ontvangt ook regelmatig updates met nieuwe functies, dus je bent bijna altijd beter af door andere marketingtags direct met GTM te implementeren dan met een andere integratie.</p><p>Bovendien zijn de winsten in laadtijd goed voor je <Link to="/nl/wiki-analytics/bouncepercentage">bouncepercentage</Link> en helpen bij SEO.</p>
        <H as="h3">Gebruik een plugin om GTM te implementeren</H>
        <p>Hieronder een lijst van de meest voorkomende content management systemen en hun plugins om Google Tag Manager te installeren.</p>
        <H as="h4">WordPress</H>
        <p>Er zijn twee WordPress plugins om GTM te implementeren die ik zou gebruiken. <b>Ten eerste</b>, is er de klassieke optie genaamd <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager
          voor WordPress</a>.<br />
          De <b>tweede</b> optie is <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit van Google</a>. Hiermee kun je voornamelijk een dashboard toevoegen aan je WordPress backend met informatie van je Google Analytics account en Google Search Console data - wat behoorlijk handig is. En het stelt je ook in staat om GTM te installeren.</p>
        <H as="h4">Shopify</H>
        <p>Voor Shopify is er een <b>gratis</b> plugin voor GTM installatie, creatief genaamd <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>Voor Squarespace is er geen GTM extensie of plugin. Maar je kunt de GTM tag handmatig toevoegen door te gaan naar <b>sidebar</b> &gt; <b>instellingen</b> &gt; <b>geavanceerd</b> &gt; <b>code injectie</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace navigatiemenu voor code injectie"
          caption={`In Squarespace kun je GTM implementeren onder Instellingen > Geavanceerd > Code Injectie`}
          className="article-img"
        />

        <p>Vervolgens plak je de GTM tag in de formuliervelden zoals dit:</p>


        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Injecteer GTM code snippets in Squarespace"
          caption={`Plaats het eerste deel van de GTM code in het header veld. Het tweede deel gaat in het footer veld.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Ga naar het hoofdmenu van je Wix website in de linker zijbalk. Ga van daaruit naar <b>Marketing &amp; SEO</b> en klik dan verder naar beneden in de zijbalk op <b>Marketing Integrations</b>.<br />
          Vervolgens vind je meerdere Wix integraties voor Google Analytics, de Facebook pixel en ook een Wix extensie om Google Tag Manager te implementeren.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix Marketing Integratie voor Google Tag Manager"
          caption={`In Wix gebruik je de marketing integratie voor Google Tag Manager.`}
          className="article-img"
        />
        <p>Klik op verbinden en installeer Google Tag Manager.</p>










        <H as="h2">Hoe controleer je of GTM werkt?</H>
        <InfoBox
          type="info"
          headline="Wanneer je voor het eerst inlogt bij GTM"
          h="false"
          dots="true"
          tweetcopy="Wanneer je voor het eerst inlogt bij GTM...Ga naar de verzendknop en publiceer een lege container. Anders, zodra je test of GTM werkt, geeft het script een 400 response error en zul je uren besteden aan het debuggen waarom. 😭"
        >
          <p>Ga naar de verzendknop en publiceer een <b>lege container</b>.</p>
          <p>Anders, zodra je test of GTM werkt, geeft het script een <b>400 response error</b> en zul je uren besteden aan het debuggen waarom. 😭 <br /><br />Het is een klassieker 😉</p>
        </InfoBox>
        <p>Nadat je het GTM-script hebt geïmplementeerd en een <b>container versie hebt gepubliceerd</b> (belangrijk), kun je testen of Google Tag Manager werkt door een van deze controles uit te voeren:</p>
        <ol>
          <li><H as="h3">Voorvertoning en debugmodus</H>Log in op je GTM-account en klik op <b>voorvertoning</b>. Open vervolgens een nieuw tabblad in de browser en bezoek je website. Het GTM-debugvenster zou onderaan het venster moeten verschijnen als GTM correct werkt.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Activeer GTM-debugmodus"
              caption={`Activeer de GTM-debugmodus om te controleren of GTM correct werkt.`}
              className="article-img"
            />
          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Open Chrome Developer Tools</b> met een rechtermuisklik op een willekeurige pagina van je site en selecteer <em>inspecteren</em> (Alternatief F12 op Windows en Shift+CTRL+J op Mac).<br />
            Ga vervolgens naar het <b>netwerk</b> tabblad en <b>herlaad de webpagina gelijktijdig</b> (F5 op Windows en CMD+Shift+R
            op Mac). Het netwerk tabblad zal zich vullen met alle netwerkverzoeken die nodig zijn om de pagina te laden.<br />
            Typ in het filterveld linksboven <em>gtm.js</em> om het verzoek voor je JavaScript-code te vinden en controleer of het
            een <b>statuscode van 200</b> heeft.<br /><br />
            Laat me je laten zien hoe:<br /><br />
            <video

              loading="lazy"
              title={`Controleer of Google Tag Manager werkt`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Als je geen 200 statuscode hebt, ben je misschien vergeten een container eerst in GTM te verzenden en te publiceren?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installeer de <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-extensie en start deze op je site. Het zou een GTM-container ID moeten oproepen.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag inspector valideert dat GTM correct laadt"
                caption={`Je kunt ook de Chrome-extensie Google Tag Assistant gebruiken om ervoor te zorgen dat Google Tag Manager correct werkt.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Hoe stel je Google Tag Manager in?</H>
        <p>Bij het instellen van Google Tag Manager kun je veel geavanceerde configuraties maken. Dus <b><em>hoe</em></b> je GTM instelt, hangt
          af van welke andere tools je van plan bent te gebruiken in je <Link to="/nl/tagbeheer">tag management systeem</Link>.</p>
        <p>Daarom heb ik alle relevante tutorials verzameld die behandelen wat je mogelijk in je GTM-account wilt instellen - met
          voorbeelden. Volg gewoon deze Google Tag Manager gids en creëer daarmee een solide tag management basis voor je site.</p>
        <p>Alleen de tutorial over het implementeren van een data layer vereist coderingsvaardigheden of mogelijk webontwikkelaars.</p>
        <p><b>Opmerking</b>: In deze Google Tag Manager tutorial, zullen we GTM gebruiken door <b>handmatig</b> nieuwe tags en triggers voor elk evenement in te stellen. De aanpak is niet super schaalbaar, maar het is snel genoeg en gemakkelijk, terwijl het voldoet aan de meeste trackingdoelen en nog steeds toepasbaar is op andere geavanceerde configuraties.</p>
        <p>Grotere websites en e-commerce winkels vereisen een <b>schaalbare tag management oplossing</b>. Daarom wordt een <Link to="/nl/data-laag">data layer</Link> geïmplementeerd als het centrale stuk om evenementen te volgen. Met zo'n opzet kun je event handlers gebruiken in plaats van tags, triggers en variabelen voor elk evenement in te stellen.</p>
        <ol>
          <li>
            <H as="h3">Stel Google Analytics tracking in</H>
            <p>Dit is de eerste stap voor iedereen. Leer in deze gids hoe je solide Google Analytics tracking implementeert, met Doelen, Trechters, en je eigen bezoeken
              uitgesloten van het verkeer. Plus meer best practices.</p>

            <Link to="/nl/google-analytics-installatie"><CtaPrimary color="purple" arrow="true">Stel Google Analytics in</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Stel event tracking in</H>
            <p>Zodra de fundamentele trackingimplementatie correct werkt, wil je ook gebruikersbetrokkenheid volgen. Hoe vaak verstuurt een bezoeker bijvoorbeeld formulierinzendingen en klikt hij op een verzendknop of een ander HTML-element? Mijn <Link to="/nl/event-tracking">event tracking</Link> gids legt precies uit hoe dat werkt voor een <b>knopklik</b> en je kunt dezelfde methode gebruiken voor elke andere kliktracking.</p>
            <Link to="/nl/event-tracking"><CtaPrimary color="purple" arrow="true">Stel event tracking in</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Voeg remarketing tags toe</H>
            <p>De meest voorkomende use-case voor GTM <em>na</em> het installeren van GA is het toevoegen van remarketing tags aan een website. Ze vormen tenslotte de meerderheid van third-party marketing tags en trackingcodes die de codebasis van onze sites vervuilen.<br />
              Daarom implementeren we ze via ons GTM-account om de codebasis schoon te houden van marketing- en analysetags, terwijl we profiteren van de <Link to="/nl/google-tag-manager-voordelen">voordelen van Google Tag Manager</Link>.</p>
            <p>Laten we leren hoe we de meest voorkomende remarketing tags in de digitale marketingruimte kunnen toevoegen, de <b>Facebook pixel</b> en de <b>Google Ads remarketing tag</b>.</p>
            <H as="h4">Voeg Facebook pixel toe</H>
            <ul>
              <li>
                Eerst heb je je <b>Facebook pixel ID</b> nodig. Bezoek Facebook’s <a rel="noopener" target="_blank" href="https://www.facebook.com/events_manager2/list/pixel/">Events Manager</a> en klik op het <b>groene plus symbool</b> om de Facebook pixel aan te maken. Vervolgens wordt je pixel ID op het scherm weergegeven.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager in Facebook toont de pixel ID"
                  caption={`Vind je Facebook pixel ID in de Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Vervolgens maak je via Google Tag Manager een <b>nieuwe tag</b> aan, noem deze bijvoorbeeld <em>Facebook - Page view</em> en bezoek de galerij voor <b>tag templates</b>.
              </li>
              <li>
                Zoek naar <em>Facebook</em> en selecteer de Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="De template galerij van GTM toont de Facebook pixel tag"
                  caption={`Implementeer de Facebook pixel uit de tag templates van GTM.`}
                  className="article-img"
                />
              </li>
              <li>
                Voeg je <b>Facebook Pixel ID</b> toe en klik op <b>opslaan</b>.
              </li>
              <li>
                Stel de tag in om op <b>alle pagina's</b> af te vuren.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installeer Facebook pixel in Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Klik vervolgens op <b>verzenden</b> in de rechterbovenhoek om de tag live te zetten.
              </li>
            </ul>
            <H as="h4">Voeg Google Ads remarketing toe</H>
            <ul>
              <li><b>Eerst</b>, krijg je <b>Google Ads conversie-ID</b> voor je doelgroep vanuit <b>Gedeelde Bibliotheek &gt; Doelgroepen</b>. De gebruikersinterface is recentelijk veranderd, maar zoek naar <b>tag details</b> of <b>tag instellen</b> om de onderstaande informatie te vinden.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Code snippets voor Google conversie-ID en conversielabel"
                    caption={`Neem je conversie-ID en conversielabel uit de tag details in je doelgroep.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Ga vervolgens in GTM naar de <b>tags</b> sectie en klik op <b>nieuw</b> om onze nieuwe marketingtag toe te voegen.</li>
              <li>Geef het een naam zoals <em>Google Ads - Page view</em>.</li>
              <li>Kies als type variabele <b>Google Ads Remarketing</b>.</li>
              <li>Stel je <b>conversie-ID</b> en optioneel het <b>conversielabel</b> in. Laat de tag vervolgens afvuren op <b>alle pagina's</b> en klik op <b>opslaan</b>.<br /><br />Laat me je dit laten zien in deze video:
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installeer Google Ads page view met GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Klik op <b>verzenden</b> in de rechterbovenhoek om de marketingtag live te zetten.</li>
            </ul>
          </li>
          <li>
            <H as="h3">Implementeer een data layer</H>
            <p>Je wilt een data layer implementeren als je regelmatig tags instelt en het te lang duurt en te arbeidsintensief is.</p>
            <p>Een ander voordeel is dat je de informatie uit je database kunt gebruiken voor het afvuren van triggers of deze kunt verzenden als gebeurtenisgegevens. Andere externe gegevensbronnen kunnen ook worden geïntegreerd. Websites die ecommerce tracking nodig hebben, vallen meestal in deze categorie.</p>
            <p>Mijn artikel over de data layer legt de implementatie uit, data layer-variabelen en hoe je aangepaste tracking op een schaalbare manier configureert, wat een typische use-case is voor <b>grote ecommerce winkels</b> die uitgebreide ecommerce tracking nodig hebben.
            </p>

            <Link to="/nl/data-laag"><CtaPrimary color="purple" arrow="true">Implementeer data layer</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Best practices voor elke GTM-setup</H>
        <p>Elke keer dat ik Google Tag Manager instel, komt de setup met een paar configuraties die ik elke keer toevoeg. Deze
          best practices zijn toepasbaar en nuttig voor de meeste bedrijven en mogen in geen enkele GTM-tutorial ontbreken. Zie de lijst hieronder en kies de nuttige voor jou.</p>
        <p>Verderop kun je een <Link to="/nl/google-tag-manager-setup#download-gtm-config-container-file">GTM setup configuratie</Link> downloaden met al deze best practices om in je eigen container te importeren.</p>
        <H as="h3">Track outbound links</H>
        <p>Om outbound link clicks te tracken betekent het tracken van alle klikken op externe links die van je website naar andere websites leiden.
          Het tracken van externe links is een best practice die je laat weten naar welke websites je bezoekers stuurt en helpt je de interesse van je bezoekers te verifiëren.<br />Om externe link tracking te implementeren, zijn er <b>drie stappen</b>:</p>
        <ol>
          <li>
            <H as="h4">Maak een aangepaste gebeurtenisvariabele in GTM</H>
            <ul>
              <li>Bezoek <b>gebruikersgedefinieerde variabelen</b> vanuit het GTM-overzicht en klik op <b>nieuw &gt; auto-gebeurtenisvariabele</b>.</li>
              <li>Het is bedoeld om de link URL van een geklikt element te evalueren en <code>true</code> te retourneren als het <code>href</code> attribuut een outbound link bevat - of <code>false</code> als de link intern is.</li>
              <li>Geef de nieuwe variabele een naam zoals <em>Linkisoutbound</em> en selecteer het <em>variabele type</em> als <b>Element URL</b> en het <em>Component Type</em> <b>is outbound</b>. Klik vervolgens op <b>opslaan</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Instellingen voor outbound link auto-gebeurtenisvariabele"
                  caption="De auto-gebeurtenisvariabele in Google Tag Manager zal een waarde van TRUE bevatten wanneer het adres van de geklikte link outbound is en naar een externe pagina wijst."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Maak een nieuwe trigger voor outbound links</H>
            <ul>
              <li>In de zijnavigatie onder triggers, klik op nieuw en maak een nieuwe trigger aan in GTM.</li>
              <li>Selecteer het <em>triggertype</em> als <b>alleen links</b> en noem het bijvoorbeeld <em>Event - Outbound Link</em>.</li>
              <li>Stel de trigger in om <em>af te vuren op sommige linkklikken</em> en selecteer je nieuwe auto-event variabele <b>Linkisoutbound</b> om gelijk te zijn aan <code>true</code>:<br />

                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Trigger configuratie voor outbound link tracking"
                  caption={`Trigger instellingen voor het tracken van outbound links.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Maak een tag voor het GA-event</H>
            <ul>
              <li>Bezoek <b>variabelen</b> en klik op <b>configureren</b> voor de <em>ingebouwde variabelen</em>. Activeer vervolgens de <b>klik URL</b> variabele. Sluit het paneel en ga naar de tags sectie.</li>
              <li>Maak een nieuwe <b>Google Analytics: Universal Analytics</b> tag aan met de naam <em>Event - Outbound link</em> en stel het <em>tracktype</em> in op <b>event</b>.</li>
              <li>Voor de <b>categorie</b> voeg je gewoon <code>Outbound link click</code> toe, <b>actie</b> is <code>click</code> en <b>label</b> moet verwijzen naar onze nieuw geactiveerde GTM-variabele. Gebruik hiervoor dubbele accolades <code>{`{{ Click URL }}`}</code>.</li>
              <li>De event <b>waarde</b> kan worden ingesteld op <code>0</code>, tenzij je een nominale waarde kunt toewijzen aan een
                externe link (bijv. voor affiliate links).</li>
              <li>De instelling <b>non-interaction hit</b> blijft ingesteld op <code>false</code>, omdat een outbound klik
                inderdaad een gebruikersinteractie is.</li>
              <li>De <b>Google Analytics instellingen variabele</b> moet dezelfde door de gebruiker gedefinieerde variabele zijn als in je tag voor pageviews.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Instellingen voor de Google Analytics event tag om outbound links te tracken"
                  caption={`Instellingen voor de event tag om outbound links te tracken.`}
                  className="article-img"
                />

              </li>
              <li>Scroll als laatste stap naar beneden naar de <em>triggering</em> sectie en <b>selecteer de nieuw aangemaakte trigger</b> <em>Event - Outbound Link</em>. Klik vervolgens op <b>opslaan</b> en <b>verzenden</b> om je containerwijzigingen vanuit het hoofdmenu in te dienen.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track klikken op e-mailadressen</H>
        <p>Het volgen van klikken op e-mails is een nuttige metriek die vaak correleert met telefoongesprekken of bezoeken aan een fysieke winkel.<br />Om Google Analytics tracking voor e-mailklikken in te stellen, volg je de stappen in de onderstaande tutorial:</p>
        <ol>
          <li>
            <H as="h4">Voeg een nieuwe trigger toe voor e-mail adres klikken</H>
            <ul>
              <li>Activeer de <b>klik URL</b> ingebouwde variabele. Je kunt deze stap overslaan als je dit al hebt gedaan in
                de vorige setup. Anders: Ga naar de <b>variabelen</b> sectie en <b>configureer</b> de <em>ingebouwde
                  variabelen</em> om de <b>klik URL</b> variabele toe te voegen. Sluit daarna het paneel en ga naar de
                triggers sectie.</li>
              <li>Onder <b>triggers</b>, klik op <em>nieuw</em> en maak een nieuwe trigger aan genaamd bijvoorbeeld <em>Event - Mail click</em>, stel het type in op <b>klik - alleen links</b> en laat het alleen afvuren bij <em>sommige linkklikken</em>.</li>
              <li>In de voorwaarde voor die linkklikken, stel je de dropdowns in op <b>Click URL</b>, vervolgens <b>bevat</b> en voeg dan gewoon de string <code>mailto:</code> toe.<br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Trigger configuratie voor het volgen van klikken op e-mailadressen"
                caption={`Trigger configuratie voor het volgen van klikken op e-mailadressen.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Stel de GA-eventtag in</H>
            <ul>
              <li>Maak een nieuwe tag aan, selecteer het tagtype <b>Google Analytics: Universal Analytics</b> en geef het de naam <em>Event - Email link</em>. Selecteer vervolgens <b>event</b> als <em>tracktype</em>.</li>
              <li>De <b>categorie</b> kan worden ingesteld op een statische string <code>Email link click</code>, <b>actie</b> is <code>click</code> en <b>label</b> moet verwijzen naar de <code>{`{{ Click URL }}`}</code>.</li>
              <li>De event <b>waarde</b> kan weer <code>0</code> zijn, tenzij je een betekenisvolle waarde kunt toewijzen. Als je bijvoorbeeld weet dat elke 10e e-mailcontact leidt tot een verkoop van 100$, dan kun je 10$ toewijzen als de gemiddelde eventwaarde.</li>
              <li>De <b>non-interaction hit</b> moet <code>false</code> laten zien, omdat de interactie vrijwillig is en daarom actief.</li>
              <li>De <b>Google Analytics-instellingen</b> variabele moet dezelfde zijn als voor je pageviewtag of outbound link tag.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Configuratie GA e-mailklik event"
                  caption={`Configuratie-instellingen van de GA-eventtag voor e-mails.`}
                  className="article-img"
                />

              </li>
              <li>De laatste stap is om naar <em>triggering</em> te gaan en <b>selecteer de trigger die we zojuist hebben gemaakt</b> <em>Event - Mail click</em>. Sla daarna <b>op</b> en ga naar het overzicht om je wijzigingen te <b>verzenden</b>.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track klikken op telefoonnummers</H>
        <p>Het volgen van klikken op telefoonnummers is vooral nuttig op mobiele apparaten. Tappen op een telefoonnummerlink start direct een telefoongesprek. Op desktops initiëren muisklikken meestal niets. Maar zoals bij het volgen van klikken op e-mails, is het een goede metriek om contactpercentages in het algemeen te bevestigen, omdat het correleert met andere methoden om contact op te nemen met een bedrijf.</p>
        <p>Leer hoe je GTM configureert voor het volgen van klikken op telefoonnummers door de onderstaande stappen te volgen.</p>
        <ol>
          <li>
            <H as="h4">Maak een trigger voor klikken op telefoonnummers</H>
            <ul>
              <li>Zoals bij de andere gidsen hierboven, hebben we de <b>klik URL</b> variabele nodig die is geactiveerd in de <em>ingebouwde variabelen</em>.</li>
              <li>Maak vervolgens een nieuwe trigger <em>Event - Phone click</em> van het type <b>klik - alleen links</b> die alleen triggert bij <em>sommige linkklikken</em>.</li>
              <li>Vul de trigger voorwaarde als volgt in: <b>Click URL</b> - <b>bevat</b> en voeg dan de string <code>tel:</code> toe. Klik vervolgens op opslaan.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM-instellingen voor een telefoonnummer trigger"
                  caption={`Instellingen voor een trigger die afgaat bij klikken op telefoonnummers.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Maak een telefoonnummer eventtag</H>
            <ul>
              <li>Voeg nogmaals een <b>Universal Analytics tag</b> toe van het type <b>event</b> en noem het <em>Event - Phone clicks</em></li>
              <li>De <b>categorie</b> is de statische string <code>Phone number click</code>, <b>actie</b> is <code>click</code> en als <b>label</b> gebruiken we opnieuw de ingebouwde variabele met dubbele accolades, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Als je geen gemiddelde waarde kunt toekennen aan een lead call, laat deze dan op <code>0</code> staan. Voeg anders een nummer toe voor de gemiddelde waarde.</li>
              <li>Event <b>non-interaction hit</b> moet worden ingesteld op <code>false</code>, omdat dit een actieve, vrijwillige interactie is. Selecteer de <b>instellingen variabele</b> uit je door de gebruiker gedefinieerde variabelen, die je ook gebruikt voor pageviews.


                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Configuratie GA telefoonnummer klik event"
                  caption={`Configuratie van het GA telefoonnummer klik event.`}
                  className="article-img"
                />
              </li>
              <li>Verbind nu je nieuw aangemaakte trigger met deze GA-eventtag door naar het triggering-gebied te gaan en
                de nieuwe trigger <em>Event - Phone click</em> te selecteren. De laatste stap is om op <b>opslaan</b> te klikken en te verzenden.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track downloads</H>
        <p>Het volgen van hoe vaak bezoekers je materialen downloaden is een goede indicator van betrokkenheid. Dergelijke downloads kunnen bijvoorbeeld eBooks, Excel-sheets, afbeeldingen, video's of muziek zijn.</p>
        <p>Het volgen van downloads werkt goed om onderscheid te maken tussen bezoekersgroepen die niet geïnteresseerd waren in de inhoud van de pagina en bezoekers die daadwerkelijk geïnteresseerd waren en hebben gedownload wat je aanbood.</p>
        <p>Volg deze tutorial om te leren hoe je download tracking instelt:</p>
        <ol>
          <li>
            <H as="h4">Configureer een GTM-trigger voor downloadklikken</H>
            <ul>
              <li>Maak een nieuwe trigger genaamd <em>Event - Downloads</em>, als <em>triggertype</em> kies <b>klik - alleen links</b> die alleen afgaat bij <b>sommige linkklikken</b>.</li>
              <li>Voor de voorwaarde stel je de ingebouwde variabele <b>Click URL</b> in op <b>eindigt met</b> de string <code>.pdf</code> om bijvoorbeeld PDF-downloads te volgen. Wijzig de extensiestring naar elk bestandstype dat je aanbiedt voor download.
              </li>
              <li>Als je <b>meerdere bestandsextensies</b> wilt volgen voor downloads, kunnen we een reguliere expressie gebruiken. Verander de voorwaarde in <b>Click URL</b> en vervolgens <b>komt overeen met RegEx (negeer hoofdlettergevoeligheid)</b> en voeg deze RegEx-string toe <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Voeg gerust andere bestandsextensies toe die relevant zijn voor je website en verwijder andere die dat niet zijn. Eindig door op <b>opslaan</b> te klikken.<br />

                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Trigger configuratie voor download tracking met een ingebouwde variabele"
                  caption={`Trigger configuratie voor download tracking met ingebouwde variabele.`}
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Volg downloads met een Google Analytics event</H>
            <ul>
              <li>Voeg een nieuwe tag toe van het type Universal Analytics, selecteer <b>event</b> als tracktype en noem het bijvoorbeeld <em>Event - Downloads</em>.
              </li>
              <li>De event <b>categorie</b> is de string <code>Download</code>, de <b>actie</b> is de string <code>click</code> en het <b>label</b> is de <code>{`{{ Click URL }}`}</code>.</li>
              <li>Event <b>non-interaction hit</b> is <code>false</code>, vanwege de interactie die een actieve betrokkenheid is.</li>
              <li>En gebruik dezelfde <b>instellingen variabele</b> als in andere trackingtags uit de door de gebruiker gedefinieerde variabelen.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Eventtag voor download tracking"
                  caption={`Instellingen van de eventtag voor download tracking.`}
                  className="article-img"
                />

              </li>
              <li>Voeg nu een trigger toe aan deze tag door naar beneden te scrollen en <em>Event - Downloads</em> te selecteren.</li>
              <li>De volgende stap is op <b>opslaan</b> te klikken, naar het overzicht te gaan en de wijzigingen te <b>verzenden</b>.</li>
            </ul>
          </li>
        </ol>
        <H as="h2">Gids om tags en triggers te testen</H>
        <p>Een Google Tag Manager tutorial zou niet compleet zijn zonder een deel over debuggen. Om een van de eerdere eventconfiguraties te testen en er zeker van te zijn dat ze werken, doe je een van de volgende dingen:</p>
        <ol>
          <li><H as="h3">GTM Preview-modus</H> Laten we eerst de voorvertoning- en debugmodus starten. Klik in het Google Tag Manager-overzicht op de <b>preview</b> knop in de rechterbovenhoek. Open vervolgens een nieuw tabblad <b>in dezelfde browser</b> en je zult merken dat een GTM-debugvenster onderaan verschijnt.<br /><br />
            Terwijl je in de preview-modus bent, voer je de tags en triggers zelf uit. Klik bijvoorbeeld op een externe link of een telefoonnummer en kijk of de
            trigger en je tag wordt weergegeven in het GTM-debugvenster samen met de eventdetails die je hebt toegevoegd.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager debugger voor events"
              className="article-img"
              caption={`De Google Tag Manager debugger opent automatisch zodra de preview-modus is geactiveerd en toont gegevens over tags, triggers en variabelen.`}
            />

          </li>
          <li><H as="h3">Google Analytics realtime rapport voor events</H>Een andere methode is om in te loggen op Google Analytics en vervolgens het <b>realtime rapport voor events</b> te bezoeken. De events zouden enkele seconden na het afvuren van de trigger moeten verschijnen.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics realtime rapport voor events"
              className="article-img"
              caption={`Je kunt GTM-configuraties verifiëren met het Google Analytics realtime rapport.`}
            />

          </li>
          <li><H as="h3">Chrome-extensie voor debuggen</H>Installeer een Chrome-extensie om de tag en trigger te debuggen. Ik raad <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> of <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a> aan, maar er zijn ook andere oplossingen beschikbaar. Zodra je de extensie aan Chrome hebt toegevoegd, kun je <b>Chrome Developer Tools</b> openen en er zal een nieuw tabblad beschikbaar zijn. Het toont je alle informatie over de afgevuurde tagmanagementregels. Als je dan je triggers in de debugmodus afvuurt, toont de extensie trigger- en eventgegevens.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome-extensie toont eventgegevens."
              className="article-img"
              caption={`Trackie Chrome-extensie toont eventgegevens in Chrome Developer Tools.`}
            />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome-extensie toont eventgegevens voor debuggen"
              className="article-img"
              caption={`Omnibug Chrome-extensie toont eventgegevens voor debuggen in Chrome Developer Tools.`}
            />
          </li>
        </ol>



        <H as="h2">Download GTM configuratie containerbestand</H>
        <p>Aangezien de bovenstaande configuraties universeel nuttig zijn voor de meeste Google Tag Manager-implementaties, heb ik de bovenstaande GTM setup als een bestand gemaakt om te importeren in andere Google Tag Manager-containers.<br />
          Het is een <code>.json</code> bestand met de instellingen en naamgevingsconventie die we hebben behandeld. Je kunt gewoon de containercode importeren zonder iets handmatig te hoeven instellen.</p>
        <p>Of je nu <b>een gloednieuwe container gebruikt</b> om tijd te besparen bij het zelf instellen van de tags, of je kunt ze <b>importeren in je bestaande container</b> en de Google Analytics instellingen variabele inclusief de tracking ID naar je eigen ID bijwerken.</p>
        <p>Je kunt deze configuraties downloaden en installeren (elk met tags, triggers en variabelen) om Google Tag Manager in te stellen:</p>
        <ul>
          <li>Track outbound links</li>
          <li>Track email klikken</li>
          <li>Track telefoonnummer klikken</li>
          <li>Track downloads</li>
        </ul>
        <p className="baseline">Importeer eenvoudig de containerinstellingen en implementeer ze. Voor demonstratiedoeleinden heb ik een Google Analytics-instellingen variabele toegevoegd met een Google Analytics-tracking ID van <em>UA-12345678-9</em>.</p>
        <p><b>Werk de GA-trackingcode bij naar je eigen</b> of
          <b>verander de GA-instellingen variabele in de tagconfiguratie naar je eigen</b>.</p>
        <p>Download de GTM setup configuratie en zie hieronder hoe je deze importeert.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Download GTM setup</CtaPrimary></a>
        <H as="h3">Importeer instellingen in je GTM-container</H>
        <p>Om het meeste uit deze GTM-tutorial te halen, volg je de onderstaande stappen en importeer je de instellingen in je GTM-container:</p>
        <ul>
          <li>ga naar <b>beheerder</b> &gt; <b>importeer container</b>.</li>
          <li>selecteer het JSON-bestand dat je zojuist hebt gedownload.</li>
          <li>kies een <b>nieuwe werkruimte</b> met bijvoorbeeld de naam <em>Import Bluerivermountains</em>.</li>
          <li>kies als <b>importoptie</b> om te <b>samenvoegen</b> en <b>hernoemen</b> van conflicterende tags, triggers en variabelen.</li>
          <li>klik op <b>bevestigen</b> en druk op de <b>verzenden</b> en de <b>publiceren</b> knop om de tags te implementeren.<br /><br />Laat me je dit laten zien in deze video:

            <video
              loading="lazy"
              title={`Tutorial video om een Google Tag Manager-container te importeren`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>
          </li>


          <li>Verander ten slotte de GA-tracking ID in de Google Analytics-instellingen variabele naar je eigen tracking ID of werk de instellingen variabele in de tags bij naar je eigen instellingen variabele.</li>
        </ul>
        <H as="h2">Opmerking over persoonlijke gegevens</H>
        <p>We moeten ons bewust zijn van de informatie die we volgen. Gegevens zijn niet zomaar gegevens, omdat landen regelgeving hebben over gegevensprivacy die van invloed zijn op het soort informatie dat we tijdens het volgen mogen verzamelen.</p>

        <p>Evenzo zijn er ook voorwaarden aan de kant van Google, die het verbieden om persoonlijke informatie te volgen en de gegevens naar hun servers te sturen.</p>

        <p>Met dat in gedachten:</p>
        <p>Over het algemeen zijn <b>e-mails of telefoonnummers persoonlijk identificeerbare informatie (PII)</b> en mogen we deze niet naar ons Google Analytics-account sturen, omdat dit <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">tegen hun servicevoorwaarden</a> is.</p>
        <p>De telefoonnummers op onze website of onze eigen zakelijke e-mailadressen zijn echter nauwelijks privé, omdat het niet de gegevens van de gebruikers zijn, maar onze eigen gegevens die openbaar beschikbaar zijn op de website.<br />
          Toch, als Google Analytics <em>ooit</em> hun database controleerde en die gegevens vond, konden ze niet weten dat het eigenlijk geen PII-gegevens zijn.<br />
          Daarom raad ik aan om geen enkel risico te nemen en <b>alle e-mailadressen en telefoonnummers die naar het Google Analytics-account worden verzonden te verbergen</b>.</p>
        <p>Simo Ahava heeft geweldig werk verricht en een <a rel="noopener" target="_blank" href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">custom task script geschreven om PII uit Google Analytics hits te verwijderen</a> en ik raad aan om dit samen met de bovenstaande configuraties te implementeren.<br />
          Het is geen vereiste, maar door het te implementeren vermijd je eventuele verwarring of je wel of geen PII-gegevens hebt.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Heb ik Google Tag Manager nodig?</H>
        <p>Ja, omdat je website waarschijnlijk Google Analytics en andere third-party script tags wil gebruiken. Het instellen van al die dingen is veel eenvoudiger en sneller met Google Tag Manager. Bovendien <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">laadt je site ook wat sneller</a>.</p>
        <H as="h3">Wanneer moet ik Google Tag Manager gebruiken?</H>
        <p>Zelfs als je alleen Google Analytics wil gebruiken, moet je Google Tag Manager gebruiken. Het instellen van <Link to="/nl/event-tracking">event tracking</Link>, cross-domain tracking of formulier tracking zijn veel voorkomende volgende stappen, maar veel <b>sneller</b> en <b>eenvoudiger</b> met GTM dan zonder. Er zijn ingebouwde triggers voor scroll tracking en video tracking, plus veel tutorials online die uitleggen hoe je <Link to="">Google Analytics instelt</Link> met GTM.</p>
        <H as="h3">Hoe gebruik ik Google Tag Manager met Google Analytics?</H>
        <p>Log in op <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> met je Google-account en krijg je Google Analytics-trackingcode inclusief de tracking ID. Voeg de Google Analytics-tag nu niet toe aan de broncode van je site. De enige hard-coded tag zou de Google Tag Manager-tag moeten zijn. Ga dus naar <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> om de GTM-code snippet te krijgen en implementeer die in plaats daarvan op elke pagina van je website. Implementeer ten slotte de GA-code via een ingebouwde tag, voeg je tracking ID toe en blijf doorgaan met <Link to="/nl/google-analytics-installatie">Google Analytics instellen</Link> <b>via Google Tag Manager</b>. Voor het toevoegen van marketingtags of het configureren van aangepaste tags gebruik je voortaan altijd GTM.</p>
        <H as="h3">Wat is het verschil tussen Google Analytics en Google Tag Manager?</H>
        <p>Google Analytics is de bibliotheek om gegevens te verzamelen over bezoeken en betrokkenheid op je website. Google Tag Manager daarentegen is een bibliotheek die op je site draait om andere bibliotheken of <em>trackingtools</em> zoals Google Analytics te implementeren. Omdat veel marketing- en analysetools extra JavaScript-snippets hebben, gebruik je de GTM-gebruikersinterface om ze allemaal in te stellen.</p>
        <H as="h3">Waar plaats ik de Google Tag Manager-code?</H>
        <p>Het <b>eerste</b> deel van de code gaat <em>zo hoog mogelijk</em> in de <code>&lt;head&gt;</code> sectie. Ik raad aan om het binnen de <code>&lt;head&gt;</code> te implementeren, maar na alle <code>&lt;style&gt;</code> of <code>&lt;script&gt;</code> tags die essentieel zijn om de pagina weer te geven - omdat we deze niet willen vertragen.<br />
          Het <b>tweede</b> deel is om een basisfunctionaliteit in browsers met uitgeschakelde JavaScript mogelijk te maken. Het gaat <em>zo hoog mogelijk</em> in het <code>&lt;body&gt;</code> element.<br />
          De logica achter de positionering van beide tags is om de vroege laadtijd van GTM te garanderen. Het stelt je in staat om aangepaste tags zo vroeg mogelijk tijdens het laden van de pagina af te vuren.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager code"
          className="article-img"
          caption={`De GTM-tag heeft twee delen. Het eerste deel wordt hoog in de head tag geplaatst en het andere direct na de opening body tag (zie hieronder).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionering van de datalayer voor de tag management tag in de broncode"
          className="article-img"
          caption={`De algemene volgorde moet altijd zijn 1. datalayer object 2. Google Tag Manager, beide in de head en 3. de andere GTM-code hoog in de body tag.`}
        />

        <H as="h3">Bevat Google Tag Manager Google Analytics?</H>
        <p>Nee, maar Google Tag Manager stelt je in staat om Google Analytics in enkele seconden te implementeren met slechts een paar klikken. Het enige wat je nodig hebt, is je <Link to="/nl/google-analytics-installatie">Google Analytics-tracking ID</Link>. Over het algemeen hoef je echter Google Analytics niet te gebruiken met Google Tag Manager. Ze zijn <b>onafhankelijk</b> van elkaar.</p>
        <H as="h3">Hoe krijg ik toegang tot Google Tag Manager?</H>
        <p>Bezoek <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> en log in met je Google-account om toegang te krijgen tot Google Tag Manager. Om GTM te gaan gebruiken, maak je een nieuw account aan en kies je als doelplatform web-eigendom. Neem vervolgens de snippet en installeer deze op elke pagina van je website.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;
